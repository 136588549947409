import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ManageAccounts as ManageAccountsIcon } from "@mui/icons-material";
import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { doc, getDoc } from "firebase/firestore";

import SkeletonManageTeamTable from "@skeletons/SkeletonManageTeamTable";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import ActionButton from "@components/ActionButton";
import Cursor from "@components/Cursor";
import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Status from "@components/Status";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import UserProfile from "@interfaces/database/UserProfile";

import EnvironmentSpecific from "@utils/components/EnvironmentSpecific";
import {
  ENVIRONMENT,
  FIRESTORE_COLLECTIONS,
  TEAM_MEMBER_ACCOUNT_TYPE,
  USER_TYPE
} from "@utils/config";
import { db, envOptions } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

dayjs.extend(relative);

interface Column {
  id:
    | "fullName"
    | "email"
    | "teamMemberAccountType"
    | "joinedDate"
    | "permissions";
  label: string;
  align?: "left" | "right" | "center";
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "fullName",
    label: "t_employer_manage_team_table_column_full_name",
    align: "left"
  },
  {
    id: "email",
    label: "t_employer_manage_team_table_column_email",
    align: "left"
  },
  {
    id: "teamMemberAccountType",
    label: "t_employer_manage_team_table_column_account_type",
    align: "left"
  },
  {
    id: "joinedDate",
    label: "t_employer_manage_team_table_column_joined_date",
    align: "left"
  },
  {
    id: "permissions",
    label: "t_general_permissions",
    align: "left"
  }
];

// TODO: Implement Sorting
const ManageTeamTable = () => {
  const companyData = useCompanyDetails();
  const toast = useToast();
  const navigate = useNavigate();
  const userProfile = useUserProfile();

  const [ownerFullName, setOwnerFullName] = useState<string>("");
  const [isOwnerDataLoading, setOwnerDataLoading] = useState<boolean>(true);

  const managerIds = Object.keys(companyData.value?.managers ?? {});
  const ownerUserId = companyData.value?.owner?.user_id ?? "";

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      `${
        envOptions.frontendUrl
          ? envOptions.frontendUrl
          : "https://" + window.location.hostname
      }/${translate.getCurrentLocale()}/employers?login_required=1&mode=sign-up`
    );
    toast.kampai(
      intl.get("t_employer_manage_team_invite_link_copied"),
      "success"
    );
  };

  const fetchOwnerData = async () => {
    // Check if current user is the owner
    if (userProfile?.value?.user_type === USER_TYPE.COMPANY) {
      const firstName =
        resolveMultiLingual(
          userProfile?.value?.summary?.basic_information?.first_name
        ) ?? "";
      const lastName =
        resolveMultiLingual(
          userProfile?.value?.summary?.basic_information?.last_name
        ) ?? "";
      const fullName = `${lastName} ${firstName}`.trim();

      setOwnerFullName(fullName);
      setOwnerDataLoading(false);
    } else {
      // Fetch owner details from DB
      if (ownerUserId) {
        setOwnerDataLoading(true);
        try {
          const userDocRef = doc(db, FIRESTORE_COLLECTIONS.USERS, ownerUserId);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data() as UserProfile;

          const firstName =
            resolveMultiLingual(
              userData?.summary?.basic_information?.first_name
            ) ?? "";
          const lastName =
            resolveMultiLingual(
              userData?.summary?.basic_information?.last_name
            ) ?? "";
          const fullName = `${lastName} ${firstName}`.trim();

          setOwnerFullName(fullName);
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
        setOwnerDataLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOwnerData();
  }, [ownerUserId]);

  if (companyData.loading) {
    return <SkeletonManageTeamTable />;
  } else {
    return (
      <DataTableWrapper>
        <TableHead>
          <TableRow>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                width="16.6%"
                align={singleColumn?.align ?? "center"}>
                <Typography variant="subtitle5">
                  {singleColumn.label ? intl.get(singleColumn.label) : ""}
                </Typography>
              </TableCell>
            ))}
            {/* Note: Last TableCell has no title. Used to display actions like copy, remove etc. */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* In first row, show owner data */}
          <TableRow className="hoverable-row">
            <TableCell align="left">
              {isOwnerDataLoading ? (
                <>
                  <SkeletonTypography variant="body2" width="short" />
                </>
              ) : (
                <Typography variant="body2">{ownerFullName}</Typography>
              )}
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2">
                {companyData.value?.owner?.email}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Status label={TEAM_MEMBER_ACCOUNT_TYPE.OWNER} />
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                <Cursor>
                  <Tooltip
                    title={dayjs(
                      companyData.value?.created_at?.toDate()
                    ).format("ddd MMM D YYYY HH:mm:ss [GMT]Z (Z)")}>
                    <span>
                      {dayjs(companyData.value?.created_at?.toDate())
                        .locale(translate.getCurrentLocaleShort())
                        .fromNow()}
                    </span>
                  </Tooltip>
                </Cursor>
              </Typography>
            </TableCell>
            {/* Note: Last two TableCell has no title. Used to display empty content */}
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {/* In the following rows, show manager data */}
          {managerIds?.map((singleManagerId, idx) => {
            const managerData = companyData.value?.managers?.[singleManagerId];
            if (managerData) {
              const firstName =
                resolveMultiLingual(managerData?.first_name) ?? "";
              const lastName =
                resolveMultiLingual(managerData?.last_name) ?? "";
              const fullName = `${lastName} ${firstName}`.trim();

              return (
                <TableRow key={idx} className="hoverable-row">
                  <TableCell align="left">
                    <Typography variant="body2">{fullName}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {managerData?.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Status
                      label={managerData?.company_manager_invitation_type}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {managerData?.joined_at ? (
                      <Typography variant="body2">
                        <Cursor>
                          <Tooltip
                            title={dayjs(
                              managerData?.joined_at?.toDate()
                            ).format("ddd MMM D YYYY HH:mm:ss [GMT]Z (Z)")}>
                            <span>
                              {dayjs(managerData?.joined_at?.toDate())
                                .locale(translate.getCurrentLocaleShort())
                                .fromNow()}
                            </span>
                          </Tooltip>
                        </Cursor>
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        !managerData?.joined_at
                          ? intl.get(
                              "t_employer_manage_team_permission_tooltip"
                            )
                          : ""
                      }
                      arrow>
                      <Box
                        sx={{ cursor: "pointer" }}
                        width="max-content"
                        onClick={() => {
                          if (managerData?.joined_at) {
                            navigate(
                              `/${translate.getCurrentLocale()}/employers/settings/manage-team/permission/${singleManagerId}`
                            );
                          }
                        }}>
                        <ManageAccountsIcon
                          color={
                            !managerData?.joined_at ? "disabled" : "inherit"
                          }
                        />
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      gap={2}
                      className="additional-column">
                      <ActionButton
                        icon="content_copy"
                        color="black"
                        disabled={!!managerData?.joined_at}
                        handleAction={() => handleCopyToClipboard()}
                      />
                      <EnvironmentSpecific env={ENVIRONMENT.STAGE}>
                        {/* TODO: Add remove logic */}
                        {managerData?.joined_at ? (
                          <Typography
                            className="additional-column"
                            variant="subtitle5"
                            color="error.main"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}>
                            {intl.get("t_employer_manage_team_remove_btn")}
                          </Typography>
                        ) : (
                          <Typography
                            className="additional-column"
                            variant="subtitle5"
                            color="error.main"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}>
                            {intl.get(
                              "t_employer_manage_team_cancel_invite_btn"
                            )}
                          </Typography>
                        )}
                      </EnvironmentSpecific>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </DataTableWrapper>
    );
  }
};

export default ManageTeamTable;
