// Note: If we change any config in this file then don't forget to update export_sitemap.ts constants accordingly

/**
 * Constants related to user status (USER_STATUS)
 *
 * OK            - This is when the user login is fine and user can proceed with any action on the platform.
 * LOCKED        - This is when the user is blocked (for whatever reason) and not allowed login to the platform.
 * DEACTIVATED   - This is when the user has dectivated his account and not allowed login to the platform.
 * NO_TYPE       - This is when a new user registration has happened and the type of the user has not been set in the DB.
 */
export const USER_STATUS = Object.freeze({
  OK: "1001",
  LOCKED: "1002",
  DEACTIVATED: "1003",
  NO_TYPE: "1999"
});

/**
 * Constants related to job posting company status (COMPANY_STATUS)
 *
 * OK            - This is when the user select hiring for client option and submit the company information form.
 * LOCKED        - This is when the user select hiring for client option in company information form.
 * DEACTIVATED   - The company account is deactivated.
 */
export const COMPANY_STATUS = Object.freeze({
  OK: "100001",
  LOCKED: "100002",
  DEACTIVATED: "100003"
});

/**
 * Constants related to job posting status (JOB_POSTING_STATUS)
 * Not to be confused with JOB_STATUS. Those are for the Job Status page of the candidate.
 *
 * DRAFT                        - This is when the job posting is in draft mode and not yet posted.
 * SUBMITTED_FOR_AUTO_REVIEW    - This is when the job posting is posted. That would be submitted for review to be handled automatically.
 * SUBMITTED_FOR_MANUAL_REVIEW  - This is when the job is submitted for manual review. For example, by flagging, etc.
 * OK_MANUAL_REVIEWED           - This is when the job posting is fine and can be seen by the candidates. (This is when the job posting is manually reviewed by the Tokhimo team).
 * OK_AUTO_REVIEWED             - This is when the job posting is fine and can be seen by the candidates. (This is when the job posting is auto reviewed by the Tokhimo AI).
 * FLAGGED                      - This is when the job posting is flagged by the feedback of the Candidates/AI and needs review. This reviw is always manual.
 * HIDDEN                       - This is when the job posting is hidden and can not be seen by the candidates.
 */
export const JOB_POSTING_STATUS = Object.freeze({
  DRAFT: "101001",
  SUBMITTED_FOR_AUTO_REVIEW: "101002",
  SUBMITTED_FOR_MANUAL_REVIEW: "101003",
  OK_AUTO_REVIEWED: "101004",
  OK_MANUAL_REVIEWED: "101005",
  FLAGGED: "101006",
  HIDDEN: "101007"
});

/**
 * Constants related to conversation status (CONVERSATION_STATUS)
 *
 * OK                               - The conversation is active and functioning normally.
 * CANDIDATE_ACCOUNT_DEACTIVATED    - The conversation cannot proceed because the candidate's account has been deactivated.
 * COMPANY_ACCOUNT_DEACTIVATED      - The conversation cannot proceed because the company's account has been deactivated.
 */
export const CONVERSATION_STATUS = Object.freeze({
  OK: "102001",
  CANDIDATE_ACCOUNT_DEACTIVATED: "102002",
  COMPANY_ACCOUNT_DEACTIVATED: "102003"
});

/**
 * Constants related to account types. (USER_TYPE)
 *
 * INDIVIDUAL           - This is when type of the account is individual and can apply for company.
 * COMPANY              - This is when type of the account is company and can create jobs and see other candidates.
 * OEM                  - This is for internal use, this allows Tokhimo to access any other user's things and do stuff on other user's behalf.
 * COMPANY_MANAGER    - This is when a company has invited another person to manage posts on their behalf. (TBD on this later).
 * SUPER_USER           - This is for Internal Tokhimo users. (They can access anything and bypass any restrictions).
 * NO_TYPE              - This is for a new registration when the type of user is not decided.
 */
export const USER_TYPE = Object.freeze({
  INDIVIDUAL: "2001",
  COMPANY: "2002",
  OEM: "2003",
  COMPANY_MANAGER: "2004",
  SUPER_USER: "2005",
  NO_TYPE: "2999"
});

/**
 * Constants related to manager invitation types. (TEAM_MEMBER_ACCOUNT_TYPE)
 *
 * INTERNAL           - This is when type of the person invited is internal within the company.
 * EXTERNAL           - This is when type of the person invited is external to the company.
 */
export const TEAM_MEMBER_ACCOUNT_TYPE = Object.freeze({
  INTERNAL: "200001",
  EXTERNAL: "200002",
  OWNER: "200003"
});

export const TEAM_MEMBER_INVITE_ACCOUNT_TYPE = Object.freeze({
  INTERNAL: "200001",
  EXTERNAL: "200002"
});

export const TEAM_MEMBER_ACCOUNT_TYPE_T_LABELS = Object.freeze({
  [TEAM_MEMBER_ACCOUNT_TYPE.INTERNAL]:
    "t_employer_manage_team_company_manager_invitation_type_internal",
  [TEAM_MEMBER_ACCOUNT_TYPE.EXTERNAL]:
    "t_employer_manage_team_company_manager_invitation_type_external",
  [TEAM_MEMBER_ACCOUNT_TYPE.OWNER]:
    "t_employer_manage_team_company_manager_invitation_type_owner"
});

/**
 * Constants related to Contract Types for Job Experiences and Job Postings. (JOB_CONTRACT_TYPE)
 * This is always related to job (either postings or filling of experience).
 *
 * FULL_TIME_EMPLOYEE   - For full time role
 * CONTRACT_EMPLOYEE    - For Contract Role
 * DISPATCH_EMPLOYEE    - For a dispatch Employee
 * FREELANCE_EMPLOYEE   - For a freelance Employee
 * INTERNSHIP           - For an intern
 * PART_TIME_JOB        - For Part Time role
 * OTHER                - Anything else.
 */
export const JOB_CONTRACT_TYPE = Object.freeze({
  FULL_TIME_EMPLOYEE: "3001",
  CONTRACT_EMPLOYEE: "3002",
  DISPATCH_EMPLOYEE: "3003",
  FREELANCE_EMPLOYEE: "3004",
  INTERNSHIP: "3005",
  PART_TIME_JOB: "3006",
  OTHER: "3007"
});

export const JOB_CONTRACT_TYPE_T_LABELS = Object.freeze({
  [JOB_CONTRACT_TYPE.FULL_TIME_EMPLOYEE]:
    "t_job_experience_contract_type_full_time_employee",
  [JOB_CONTRACT_TYPE.CONTRACT_EMPLOYEE]:
    "t_job_experience_contract_type_contract_employee",
  [JOB_CONTRACT_TYPE.DISPATCH_EMPLOYEE]:
    "t_job_experience_contract_type_dispatch_employee",
  [JOB_CONTRACT_TYPE.FREELANCE_EMPLOYEE]:
    "t_job_experience_contract_type_freelance_employee",
  [JOB_CONTRACT_TYPE.INTERNSHIP]: "t_job_experience_contract_type_internship",
  [JOB_CONTRACT_TYPE.PART_TIME_JOB]:
    "t_job_experience_contract_type_part_time_job",
  [JOB_CONTRACT_TYPE.OTHER]: "t_job_experience_contract_type_other"
});

/**
 * Constants related to japanese language proficiencies. (LANGUAGE_PROFICIENCY)
 * This is used for both Job Postings and User Profile - ja language section.
 * That is why this is a generic constant.
 *
 * NATIVE               - Native Speaker.
 * BUSINESS             - Business Speaker.
 * DAILY_CONVERSATION   - Has basic knowledge.
 * NONE                 - Does not know anything about that language.
 */
export const LANGUAGE_PROFICIENCY = Object.freeze({
  NATIVE: "4001",
  BUSINESS: "4002",
  DAILY_CONVERSATION: "4003",
  NONE: "4004"
});

export const LANGUAGE_PROFICIENCY_T_LABELS = Object.freeze({
  [LANGUAGE_PROFICIENCY.NATIVE]: "t_language_proficiency_native",
  [LANGUAGE_PROFICIENCY.BUSINESS]: "t_language_proficiency_business",
  [LANGUAGE_PROFICIENCY.DAILY_CONVERSATION]:
    "t_language_proficiency_conversational",
  [LANGUAGE_PROFICIENCY.NONE]: "t_language_proficiency_none"
});

/**
 * Constants related to other language section proficiencies. (OTHER_LANGUAGE_PROFICIENCY)
 * This is used for User Profile - other language section.
 *
 * NATIVE               - Native Speaker.
 * BUSINESS             - Business Speaker.
 * DAILY_CONVERSATION   - Has basic knowledge.
 */
export const OTHER_LANGUAGE_PROFICIENCY = Object.freeze({
  NATIVE: "4001",
  BUSINESS: "4002",
  DAILY_CONVERSATION: "4003"
});

export const OTHER_LANGUAGE_PROFICIENCY_T_LABELS = Object.freeze({
  [LANGUAGE_PROFICIENCY.NATIVE]: "t_language_proficiency_native",
  [LANGUAGE_PROFICIENCY.BUSINESS]: "t_language_proficiency_business",
  [LANGUAGE_PROFICIENCY.DAILY_CONVERSATION]:
    "t_language_proficiency_conversational"
});

/**
 * Constants related to account types. (INDIVIDUAL_USER_VISA_TYPE)
 *
 * VALUES - TODO
 */
export const INDIVIDUAL_USER_VISA_TYPE = Object.freeze({
  ARTIST: "5001",
  BUSINESS_MANAGER: "5002",
  CULTURAL_ACTIVITIES: "5003",
  DEPENDANT: "5004",
  DIPLOMAT: "5005",
  DESIGNATED_ACTIVITIES: "5006",
  ENTERTAINER: "5007",
  HIGHLY_SKILLED_PROFESSIONAL: "5008",
  INSTRUCTOR: "5009",
  INTRA_COMPANY_TRANSFEREE: "5010",
  JOURNALIST: "5011",
  LEGAL_ACCOUNTING_SERVICES: "5012",
  LONG_TERM_RESIDENT: "5013",
  MEDICAL_SERVICES: "5014",
  NURSING_CARE: "5015",
  OFFICIAL: "5016",
  PERMANENT_RESIDENT: "5017",
  PROFESSOR: "5018",
  RELIGIOUS_ACTIVITIES: "5019",
  RESEARCHER: "5020",
  SPECIALIST: "5021",
  SKILLED_LABOR: "5022",
  SPOUSE_CHILD_JA_NATIONAL: "5023",
  SPOUSE_CHILD_PERMA_RESIDENT: "5024",
  SPECIFIED_SKILLED_WORKERS: "5025",
  STUDENT: "5026",
  TECHNICAL_INTERN_TRAINING: "5027",
  TEMPORARY_VISITOR: "5028",
  TRAINEE: "5029"
});

export const INDIVIDUAL_USER_VISA_TYPE_T_LABELS = Object.freeze({
  [INDIVIDUAL_USER_VISA_TYPE.ARTIST]: "t_user_visa_type_artist",
  [INDIVIDUAL_USER_VISA_TYPE.BUSINESS_MANAGER]:
    "t_user_visa_type_business_manager",
  [INDIVIDUAL_USER_VISA_TYPE.CULTURAL_ACTIVITIES]:
    "t_user_visa_type_cultural_activities",
  [INDIVIDUAL_USER_VISA_TYPE.DEPENDANT]: "t_user_visa_type_dependent",
  [INDIVIDUAL_USER_VISA_TYPE.DESIGNATED_ACTIVITIES]:
    "t_user_visa_type_designated_activities",
  [INDIVIDUAL_USER_VISA_TYPE.DIPLOMAT]: "t_user_visa_type_diplomat",
  [INDIVIDUAL_USER_VISA_TYPE.SPECIALIST]:
    "t_user_visa_type_engineer_specialist",
  [INDIVIDUAL_USER_VISA_TYPE.ENTERTAINER]: "t_user_visa_type_entertainer",
  [INDIVIDUAL_USER_VISA_TYPE.HIGHLY_SKILLED_PROFESSIONAL]:
    "t_user_visa_type_highly_skilled_professional",
  [INDIVIDUAL_USER_VISA_TYPE.INSTRUCTOR]: "t_user_visa_type_instructor",
  [INDIVIDUAL_USER_VISA_TYPE.INTRA_COMPANY_TRANSFEREE]:
    "t_user_visa_type_intra_company_transferee",
  [INDIVIDUAL_USER_VISA_TYPE.JOURNALIST]: "t_user_visa_type_journalist",
  [INDIVIDUAL_USER_VISA_TYPE.LEGAL_ACCOUNTING_SERVICES]:
    "t_user_visa_type_legal_accounting_services",
  [INDIVIDUAL_USER_VISA_TYPE.LONG_TERM_RESIDENT]:
    "t_user_visa_type_long_term_resident",
  [INDIVIDUAL_USER_VISA_TYPE.MEDICAL_SERVICES]:
    "t_user_visa_type_medical_services",
  [INDIVIDUAL_USER_VISA_TYPE.NURSING_CARE]: "t_user_visa_type_nursing_care",
  [INDIVIDUAL_USER_VISA_TYPE.OFFICIAL]: "t_user_visa_type_official",
  [INDIVIDUAL_USER_VISA_TYPE.PERMANENT_RESIDENT]:
    "t_user_visa_type_permanent_resident",
  [INDIVIDUAL_USER_VISA_TYPE.PROFESSOR]: "t_user_visa_type_professor",
  [INDIVIDUAL_USER_VISA_TYPE.RELIGIOUS_ACTIVITIES]:
    "t_user_visa_type_religious_activities",
  [INDIVIDUAL_USER_VISA_TYPE.RESEARCHER]: "t_user_visa_type_researcher",
  [INDIVIDUAL_USER_VISA_TYPE.SKILLED_LABOR]: "t_user_visa_type_skilled_labor",
  [INDIVIDUAL_USER_VISA_TYPE.SPECIFIED_SKILLED_WORKERS]:
    "t_user_visa_type_specified_skilled_worker",
  [INDIVIDUAL_USER_VISA_TYPE.SPOUSE_CHILD_JA_NATIONAL]:
    "t_user_visa_type_spouse_or_child_of_japanese_national",
  [INDIVIDUAL_USER_VISA_TYPE.SPOUSE_CHILD_PERMA_RESIDENT]:
    "t_user_visa_type_spouse_or_child_of_permanent_resident",
  [INDIVIDUAL_USER_VISA_TYPE.STUDENT]: "t_user_visa_type_student",
  [INDIVIDUAL_USER_VISA_TYPE.TECHNICAL_INTERN_TRAINING]:
    "t_user_visa_type_technical_intern_training",
  [INDIVIDUAL_USER_VISA_TYPE.TEMPORARY_VISITOR]:
    "t_user_visa_type_temporary_visitor",
  [INDIVIDUAL_USER_VISA_TYPE.TRAINEE]: "t_user_visa_type_trainee"
});

/**
 * Constants related to account types. (INDIVIDUAL_USER_DEGREE_TYPE)
 *
 * VALUES - TODO
 */
export const INDIVIDUAL_USER_DEGREE_TYPE = Object.freeze({
  DOCTOR: "6001",
  MASTER: "6002",
  BACHELOR: "6003",
  ASSOCIATE_DEGREE: "6004",
  ADVANCED_DIPLOMA: "6005",
  DIPLOMA: "6006",
  EXCHANGE_STUDENT: "6007",
  HIGHT_SCHOOL: "6008"
});

export const INDIVIDUAL_USER_DEGREE_TYPE_T_LABELS = Object.freeze({
  [INDIVIDUAL_USER_DEGREE_TYPE.DOCTOR]: "t_education_degree_type_doctor",
  [INDIVIDUAL_USER_DEGREE_TYPE.MASTER]: "t_education_degree_type_master",
  [INDIVIDUAL_USER_DEGREE_TYPE.BACHELOR]: "t_education_degree_type_bachelor",
  [INDIVIDUAL_USER_DEGREE_TYPE.ASSOCIATE_DEGREE]:
    "t_education_degree_type_associate_degree",
  [INDIVIDUAL_USER_DEGREE_TYPE.ADVANCED_DIPLOMA]:
    "t_education_degree_type_advanced_diploma",
  [INDIVIDUAL_USER_DEGREE_TYPE.DIPLOMA]: "t_education_degree_type_diploma",
  [INDIVIDUAL_USER_DEGREE_TYPE.EXCHANGE_STUDENT]:
    "t_education_degree_type_exchange_student",
  [INDIVIDUAL_USER_DEGREE_TYPE.HIGHT_SCHOOL]:
    "t_education_degree_type_hight_school"
});

// TODO: Confirm about these values.
// {
//     "dropdown_name": "Scale",
//     "options": [
//         "~ Less than 50",
//         "50 ~ 100",
//         "100 ～ 300",
//         "300 ～ 500",
//         "500 ～ 1000",
//         "1000 ～ 3000",
//         "3000 ～ 5000",
//         "5000 or More"
//     ]
// },

// {
//     "dropdown_name": "Job change",
//     "options": [
//         "Soon",
//         "Within 3 Months",
//         "Within 6 Months",
//         "Within 1 Year",
//         "Not Decided Yet"
//     ]
// }

/**
 * Constants related to dialog(modal) actions. (DIALOG_ACTION)
 *
 * AGREE   - To AGREE/CONFIRM and close dialog
 * CANCEL  - To CANCEL and close dialog
 */
export const DIALOG_ACTION = Object.freeze({
  AGREE: "AGREE",
  CANCEL: "CANCEL"
});

/**
 * Constants related to job status. (JOB_STATUS_TAGS)
 * These constants are for frontend display and not for DB references.
 * For DB references, check - JOB_APPLICATION_INVITATION_ACTION_TYPE
 * FIXME: Update this documentation
 *
 * NEW        - For new jobs
 * ACTIVE     - For active jobs
 * APPLIED    - For applied jobs
 * REVOKED    - For revoked jobs
 * INVITED    - For invited jobs
 * AGENCY    -  For jobs posted by agency
 */
export const JOB_STATUS_TAGS = Object.freeze({
  NEW: "NEW",
  ACTIVE: "ACTIVE",
  AGENCY: "AGENCY"
});

export const JOB_APPLICATION_INVITATION_ACTION_TYPE = Object.freeze({
  INVITED: "7001",
  APPLIED: "7002",
  DECLINED: "7003",
  REVOKED: "7004",
  RESUME_PURCHASED: "7005",
  INVITED_DECLINED: "7006",
  HIRING_FLOW_STEP_CHANGED: "7007",
  HIRED: "7008",
  EMPLOYER_REVOKED: "7009"
});

export const JOB_APPLICATION_INVITATION_ACTION_TYPE_T_LABELS = Object.freeze({
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED]: "t_general_invited",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED]: "t_general_applied",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED]:
    "t_general_employer_declined_employer_view",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED]: "t_general_revoked",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.RESUME_PURCHASED]:
    "t_general_resume_purchased",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED]:
    "t_general_candidate_invited_declined",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED]: "t_general_hired",
  [JOB_APPLICATION_INVITATION_ACTION_TYPE.EMPLOYER_REVOKED]:
    "t_general_employer_revoked"
});

/**
 * Constants related to candidate status. (CANDIDATE_STATUS_TAGS)
 * These constants are for frontend display and not for DB references.
 *
 * ACTIVE     - For active candidates
 */
export const CANDIDATE_STATUS_TAGS = Object.freeze({
  ACTIVE: "CANDIDATE_ACTIVE"
});

/**
 * Constants related to Social Button. (SOCIAL_BUTTON_VARIANT)
 *
 * GOOGLE   - For Google SSO
 * LINKEDIN - For LinkedIn SSO
 */
export const SOCIAL_BUTTON_VARIANT = Object.freeze({
  GOOGLE: "GOOGLE",
  LINKEDIN: "LINKEDIN"
});

// Ellipsis typography default values
export const ELLIPSIS_TYPOGRAPHY_DEFAULT_VALUES = Object.freeze({
  lineClamp: 4,
  color: "text.secondary"
});

// Toast notification time
export const TOAST_DEFAULT_HIDE_DURATION_MS = 3000;

/**
 * Constants related to File Upload Requirements
 *
 * fileSize   - For maximum permitted size of file (in MB)
 * fileFormat - For permitted formats
 */
export const CV_FILE_UPLOAD_CONFIG = Object.freeze({
  fileSize: 6,
  fileFormat: ["pdf"]
});

/**
 * Constants related to job details File Upload Requirements
 *
 * fileSize   - For maximum permitted size of file (in MB)
 * fileFormat - For permitted formats
 */
export const JOB_DESCRIPTION_FILE_UPLOAD_CONFIG = Object.freeze({
  fileSize: 6,
  fileFormat: ["pdf"]
});

/**
 * Constants related to base avatar component
 *
 * fileSize   - For maximum permitted size of file (in MB)
 * fileFormat - For permitted formats
 */
export const AVATAR_IMAGE_UPLOAD_CONFIG = Object.freeze({
  fileSize: 5,
  fileFormat: ["png", "jpeg", "jpg", "gif", "webp"]
});

/**
 * Constants related to Image Upload Requirements
 *
 * fileSize   - For maximum permitted size of file (in MB)
 * fileFormat - For permitted formats
 */
export const PROFILE_IMAGE_UPLOAD_CONFIG = Object.freeze({
  fileSize: 5,
  fileFormat: ["png", "jpeg", "jpg", "gif", "webp"]
});

/**
 * Constants related to company logo
 *
 * fileSize   - For maximum permitted size of file (in MB)
 * fileFormat - For permitted formats
 */
export const COMPANY_LOGO_UPLOAD_CONFIG = Object.freeze({
  fileSize: 5,
  fileFormat: ["png", "jpeg", "jpg", "gif", "webp"]
});

/**
 * Constants related to Environment Types
 *
 * LOCAL   - For when the environment is locally served.
 * PREVIEW - For when the environment is serverd as PR Preview.
 * STAGE   - For when the environment is served on the Staging Website.
 * TEST    - For when the environment is served on the Test Pipeline.
 * PROD    - For when the environment is served on the Prod Website.
 */
export const ENVIRONMENT = Object.freeze({
  LOCAL: "LOCAL",
  PREVIEW: "PREVIEW",
  STAGE: "STAGE",
  TEST: "TEST",
  PROD: "PROD"
});

// Constants related to number of companies user worked
export const INDIVIDUAL_USER_NUMBER_OF_COMPANIES = Object.freeze({
  ZERO: "0",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
  EIGHT: "8",
  NINE: "9",
  TEN: "10",
  GREATER_THAN_TEN: "9999"
});

export const INDIVIDUAL_USER_NUMBER_OF_COMPANIES_T_LABELS = Object.freeze({
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.ZERO]:
    "t_profile_number_of_companies_zero",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.ONE]:
    "t_profile_number_of_companies_one",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.TWO]:
    "t_profile_number_of_companies_two",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.THREE]:
    "t_profile_number_of_companies_three",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.FOUR]:
    "t_profile_number_of_companies_four",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.FIVE]:
    "t_profile_number_of_companies_five",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.SIX]:
    "t_profile_number_of_companies_six",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.SEVEN]:
    "t_profile_number_of_companies_seven",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.EIGHT]:
    "t_profile_number_of_companies_eight",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.NINE]:
    "t_profile_number_of_companies_nine",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.TEN]:
    "t_profile_number_of_companies_ten",
  [INDIVIDUAL_USER_NUMBER_OF_COMPANIES.GREATER_THAN_TEN]:
    "t_profile_number_of_companies_greater_than_ten"
});

/**
 * Constants related to years of experience in job (YEARS_OF_EXPERIENCE)
 * This us used in both User's Job Skill setting and while posting a Job from company side.
 * That's why this is general YEARS_OF_EXPERIENCE and not INDIVIDUAL_USER_YEARS_OF_EXPERIENCE or JOB_YEARS_OF_EXPERIENCE.
 * FIXME: Add comment about the values.
 */
export const YEARS_OF_EXPERIENCE = Object.freeze({
  LESS_THAN_ONE_YEAR: "1",
  ONE_YEAR: "2",
  TWO_YEARS: "3",
  THREE_YEARS: "4",
  FOUR_YEARS: "5",
  FIVE_YEARS: "6",
  SIX_YEARS: "7",
  SEVEN_YEARS: "8",
  EIGHT_YEARS: "9",
  NINE_YEARS: "10",
  TEN_YEARS: "11",
  GREATER_THAN_TEN_YEARS: "9999"
});

export const YEARS_OF_EXPERIENCE_T_LABELS = Object.freeze({
  [YEARS_OF_EXPERIENCE.LESS_THAN_ONE_YEAR]:
    "t_general_year_of_experience_less_than_one_year",
  [YEARS_OF_EXPERIENCE.ONE_YEAR]: "t_general_year_of_experience_one_year",
  [YEARS_OF_EXPERIENCE.TWO_YEARS]: "t_general_year_of_experience_two_years",
  [YEARS_OF_EXPERIENCE.THREE_YEARS]: "t_general_year_of_experience_three_years",
  [YEARS_OF_EXPERIENCE.FOUR_YEARS]: "t_general_year_of_experience_four_years",
  [YEARS_OF_EXPERIENCE.FIVE_YEARS]: "t_general_year_of_experience_five_years",
  [YEARS_OF_EXPERIENCE.SIX_YEARS]: "t_general_year_of_experience_six_years",
  [YEARS_OF_EXPERIENCE.SEVEN_YEARS]: "t_general_year_of_experience_seven_years",
  [YEARS_OF_EXPERIENCE.EIGHT_YEARS]: "t_general_year_of_experience_eight_years",
  [YEARS_OF_EXPERIENCE.NINE_YEARS]: "t_general_year_of_experience_nine_years",
  [YEARS_OF_EXPERIENCE.TEN_YEARS]: "t_general_year_of_experience_ten_years",
  [YEARS_OF_EXPERIENCE.GREATER_THAN_TEN_YEARS]:
    "t_general_year_of_experience_greater_than_ten_years"
});

export const MIN_YEARS_OF_EXPERIENCE = Object.freeze({
  LESS_THAN_ONE_YEAR: YEARS_OF_EXPERIENCE.LESS_THAN_ONE_YEAR,
  ONE_YEAR: YEARS_OF_EXPERIENCE.ONE_YEAR,
  TWO_YEARS: YEARS_OF_EXPERIENCE.TWO_YEARS,
  THREE_YEARS: YEARS_OF_EXPERIENCE.THREE_YEARS,
  FOUR_YEARS: YEARS_OF_EXPERIENCE.FOUR_YEARS,
  FIVE_YEARS: YEARS_OF_EXPERIENCE.FIVE_YEARS,
  SIX_YEARS: YEARS_OF_EXPERIENCE.SIX_YEARS,
  SEVEN_YEARS: YEARS_OF_EXPERIENCE.SEVEN_YEARS,
  EIGHT_YEARS: YEARS_OF_EXPERIENCE.EIGHT_YEARS,
  NINE_YEARS: YEARS_OF_EXPERIENCE.NINE_YEARS,
  TEN_YEARS: YEARS_OF_EXPERIENCE.TEN_YEARS,
  GREATER_THAN_TEN_YEARS: YEARS_OF_EXPERIENCE.GREATER_THAN_TEN_YEARS
});

export const MIN_YEARS_OF_EXPERIENCE_T_LABELS = Object.freeze({
  [MIN_YEARS_OF_EXPERIENCE.LESS_THAN_ONE_YEAR]:
    "t_job_card_year_of_experience_min_less_than_one_year",
  [MIN_YEARS_OF_EXPERIENCE.ONE_YEAR]:
    "t_job_card_year_of_experience_min_one_year",
  [MIN_YEARS_OF_EXPERIENCE.TWO_YEARS]:
    "t_job_card_year_of_experience_min_two_years",
  [MIN_YEARS_OF_EXPERIENCE.THREE_YEARS]:
    "t_job_card_year_of_experience_min_three_years",
  [MIN_YEARS_OF_EXPERIENCE.FOUR_YEARS]:
    "t_job_card_year_of_experience_min_four_years",
  [MIN_YEARS_OF_EXPERIENCE.FIVE_YEARS]:
    "t_job_card_year_of_experience_min_five_years",
  [MIN_YEARS_OF_EXPERIENCE.SIX_YEARS]:
    "t_job_card_year_of_experience_min_six_years",
  [MIN_YEARS_OF_EXPERIENCE.SEVEN_YEARS]:
    "t_job_card_year_of_experience_min_seven_years",
  [MIN_YEARS_OF_EXPERIENCE.EIGHT_YEARS]:
    "t_job_card_year_of_experience_min_eight_years",
  [MIN_YEARS_OF_EXPERIENCE.NINE_YEARS]:
    "t_job_card_year_of_experience_min_nine_years",
  [MIN_YEARS_OF_EXPERIENCE.TEN_YEARS]:
    "t_job_card_year_of_experience_min_ten_years",
  [MIN_YEARS_OF_EXPERIENCE.GREATER_THAN_TEN_YEARS]:
    "t_job_card_year_of_experience_min_greater_than_ten_years"
});

// FIXME: remove/fix below comment once auto complete component fixed
// export const SKILLS_LIST = [
//   "Node",
//   "React",
//   "Javascript",
//   "Mongodb",
//   "Python",
//   "C",
//   "C++",
//   "PHP"
// ].sort();

export const MAX_SKILLS = 50;
export const MAX_WORK_EXPERIENCES = 20;
export const MAX_EDUCATIONS = 10;
export const MAX_CERTIFICATIONS = 20;
export const MAX_LANGUAGES = 10;
export const MAX_EXPECTED_SALARY = 50000000;
export const MAX_RESUME_SCREENING_STEPS = 10;
export const MAX_INTERVIEW_STEPS = 10;
export const MAX_OFFER_STEPS = 10;

// Constants related to file.
// FIXME: Documentation will be done later.
// Note: The Paths DO NOT contain trailing slashes.
export const FILES_LOCATION_USER = "user";
export const FILES_LOCATION_TOKHIMO = "tokhimo";

export const FILES_LOCATION_INDIVIDUAL = `${FILES_LOCATION_USER}/individual`;
export const FILES_LOCATION_COMPANY = `${FILES_LOCATION_USER}/company`;

export const FILES_LOCATION_INDIVIDUAL_EN_CV = `${FILES_LOCATION_INDIVIDUAL}/resume/en-cv`;
export const FILES_LOCATION_INDIVIDUAL_JA_CV = `${FILES_LOCATION_INDIVIDUAL}/resume/ja-cv`;
export const FILES_LOCATION_INDIVIDUAL_JA_RIREKISHO = `${FILES_LOCATION_INDIVIDUAL}/resume/ja-rirekisho`;

export const FILES_LOCATION_INDIVIDUAL_AUTOMATED_EN_CV = `${FILES_LOCATION_INDIVIDUAL}/resume/automated-en-cv`;
export const FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_CV = `${FILES_LOCATION_INDIVIDUAL}/resume/automated-ja-cv`;
export const FILES_LOCATION_INDIVIDUAL_AUTOMATED_JA_RIREKISHO = `${FILES_LOCATION_INDIVIDUAL}/resume/automated-ja-rirekisho`;

export const FILES_LOCATION_PROFILE_PICTURE = `${FILES_LOCATION_USER}/profile-picture`;
export const FILES_LOCATION_COMPANY_LOGO = `${FILES_LOCATION_COMPANY}/logo`;

export const FILES_LOCATION_JOB = `${FILES_LOCATION_COMPANY}/jobs`;
export const FILES_LOCATION_JOB_ADDITIONAL_FILE = `${FILES_LOCATION_JOB}/additional-files`;

export const JA_LANGUAGE_TEST = Object.freeze({
  JLPT: "9002",
  NAT: "9003",
  BJT: "9004",
  J_TEST: "9005"
});

export const JA_LANGUAGE_TEST_T_LABELS = Object.freeze({
  [JA_LANGUAGE_TEST.JLPT]: "t_ja_language_test_jlpt",
  [JA_LANGUAGE_TEST.NAT]: "t_ja_language_test_nat",
  [JA_LANGUAGE_TEST.BJT]: "t_ja_language_test_bjt",
  [JA_LANGUAGE_TEST.J_TEST]: "t_ja_language_test_j_test"
});

// Constants related to language score
export const JLPT_SCORE = Object.freeze({
  N1: "10001",
  N2: "10002",
  N3: "10003",
  N4: "10004",
  N5: "10005"
});

export const JLPT_SCORE_T_LABELS = Object.freeze({
  [JLPT_SCORE.N1]: "t_jlpt_score_n1",
  [JLPT_SCORE.N2]: "t_jlpt_score_n2",
  [JLPT_SCORE.N3]: "t_jlpt_score_n3",
  [JLPT_SCORE.N4]: "t_jlpt_score_n4",
  [JLPT_SCORE.N5]: "t_jlpt_score_n5"
});

export const NAT_SCORE = Object.freeze({
  LEVEL_1: "20001",
  LEVEL_2: "20002",
  LEVEL_3: "20003",
  LEVEL_4: "20004",
  LEVEL_5: "20005"
});

export const NAT_SCORE_T_LABELS = Object.freeze({
  [NAT_SCORE.LEVEL_1]: "t_nat_score_level_1",
  [NAT_SCORE.LEVEL_2]: "t_nat_score_level_2",
  [NAT_SCORE.LEVEL_3]: "t_nat_score_level_3",
  [NAT_SCORE.LEVEL_4]: "t_nat_score_level_4",
  [NAT_SCORE.LEVEL_5]: "t_nat_score_level_5"
});

export const BJT_SCORE = Object.freeze({
  J1: "30001",
  J2: "30002",
  J3: "30003",
  J4: "30004",
  J5: "30005"
});

export const BJT_SCORE_T_LABELS = Object.freeze({
  [BJT_SCORE.J1]: "t_bjt_score_j1",
  [BJT_SCORE.J2]: "t_bjt_score_j2",
  [BJT_SCORE.J3]: "t_bjt_score_j3",
  [BJT_SCORE.J4]: "t_bjt_score_j4",
  [BJT_SCORE.J5]: "t_bjt_score_j5"
});

export const J_TEST_SCORE = Object.freeze({
  "A": "40001",
  "A+": "40002",
  "A-": "40003",
  "B": "40004",
  "B-": "40005",
  "C": "40006",
  "D": "40007",
  "E": "40008",
  "F": "40009",
  "G": "40010"
});

export const J_TEST_SCORE_T_LABELS = Object.freeze({
  [J_TEST_SCORE.A]: "t_j_test_score_a",
  [J_TEST_SCORE["A+"]]: "t_j_test_score_a+",
  [J_TEST_SCORE["A-"]]: "t_j_test_score_a-",
  [J_TEST_SCORE.B]: "t_j_test_score_b",
  [J_TEST_SCORE["B-"]]: "t_j_test_score_b-",
  [J_TEST_SCORE.C]: "t_j_test_score_c",
  [J_TEST_SCORE.D]: "t_j_test_score_d",
  [J_TEST_SCORE.E]: "t_j_test_score_e",
  [J_TEST_SCORE.F]: "t_j_test_score_f",
  [J_TEST_SCORE.G]: "t_j_test_score_g"
});

export const INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_EN_CV = 6;
export const INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_CV = 6;
export const INDIVIDUAL_USER_PROFILE_COMPLETION_TOTAL_PROGRESS_STEPS_JA_RIREKISHO = 1;

/**
 * Constants related to State management of SignInSignUpDialog.
 *
 * SIGN_IN        - Active screen is the email and password input screen for sign in.
 * SIGN_UP        - Active screen is the email and password input screen for sign up.
 * TEST           - Active screen for forgot password flow where user enters the email.
 * PROD           - Active screen for forgot password flow where password recovery email has been sent.
 */
export const SIGN_IN_SIGN_UP_DIALOG_ACTIVE_CONTENT_KEY = Object.freeze({
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  RESET_PASSWORD_EMAIL_INPUT: "RESET_PASSWORD_EMAIL_INPUT",
  RESET_PASSWORD_EMAIL_SENT: "RESET_PASSWORD_EMAIL_SENT"
});

/**
 * Constants related to the job that has been posted with regards to the company of posting.
 * (JOB_POSTING_TYPE)
 *
 * SELF - The company has posted for itself.
 * CLIENT - The company is posting on behalf of a client.
 */
export const JOB_POSTING_TYPE = Object.freeze({
  SELF: "50001",
  CLIENT: "50002"
});

/**
 * Constants related to visa sponsorship for a job posting.
 * (JOB_POSTING_TYPE)
 *
 * YES - The company is providing visa sponsorship.
 * No - The company is not providing visa sponsorship.
 */
export const JOB_VISA_SPONSORSHIP_AVAILABLE = Object.freeze({
  YES: "8001",
  NO: "8002"
});

export const JOB_VISA_SPONSORSHIP_AVAILABLE_T_LABELS = Object.freeze({
  [JOB_VISA_SPONSORSHIP_AVAILABLE.YES]: "t_general_yes",
  [JOB_VISA_SPONSORSHIP_AVAILABLE.NO]: "t_general_no"
});

/**
 * Constants related to the frequency of salary deposit for a job posting.
 * (JOB_SALARY_DEPOSIT_FREQUENCY)
 *
 * HOURLY     - Pay is per hour of work.
 * DAILY      - Payment is at end of every day (8h/day).
 * WEEKLY     - Payment is made at the end of every week.
 * BI_WEEKLY  - Once every two weeks the payment is made.
 * MONTHLY    - Salary is credited once every month.
 * QUARTERLY   - Payment is made once every 3 months.
 * ANNUALLY   - Cost to company on a per year basis.
 */
export const JOB_SALARY_DEPOSIT_FREQUENCY = Object.freeze({
  HOURLY: "51001",
  DAILY: "51002",
  WEEKLY: "51003",
  BI_WEEKLY: "51004",
  MONTHLY: "51005",
  QUARTERLY: "51006",
  ANNUALLY: "51007"
});

export const JOB_SALARY_DEPOSIT_FREQUENCY_T_LABELS = Object.freeze({
  [JOB_SALARY_DEPOSIT_FREQUENCY.HOURLY]: "t_job_salary_frequency_hourly",
  [JOB_SALARY_DEPOSIT_FREQUENCY.DAILY]: "t_job_salary_frequency_daily",
  [JOB_SALARY_DEPOSIT_FREQUENCY.WEEKLY]: "t_job_salary_frequency_weekly",
  [JOB_SALARY_DEPOSIT_FREQUENCY.BI_WEEKLY]: "t_job_salary_frequency_bi_weekly",
  [JOB_SALARY_DEPOSIT_FREQUENCY.MONTHLY]: "t_job_salary_frequency_monthly",
  [JOB_SALARY_DEPOSIT_FREQUENCY.QUARTERLY]: "t_job_salary_frequency_quarterly",
  [JOB_SALARY_DEPOSIT_FREQUENCY.ANNUALLY]: "t_job_salary_frequency_annually"
});

// Ref1:- https://stackoverflow.com/a/3809435
// Ref2:- https://regexr.com/3e6m0
/* eslint-disable no-useless-escape */
export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

// Ref1:- https://regexr.com/3e6m0
export const WYSIWYG_URL_REGEX = /\b(?:https?|www)\b|https?:\/\/|www\./gi;

// Ref:- https://stackoverflow.com/a/75454523/13285590
export const ALLOW_ONLY_NON_NEGATIVE_INTEGERS_REGEX = /[^0-9]/g;

/**
 * Constants related to the type of remote work for a job posting.
 * (JOB_REMOTE_WORK_TYPE)
 *
 * FULLY_REMOTE - The job is fully remote.
 * PARTIALLY_REMOTE - The job is partially remote.
 * NOT_REMOTE - The job is not remote.
 */
export const JOB_REMOTE_WORK_TYPE = Object.freeze({
  FULLY_REMOTE: "52001",
  PARTIALLY_REMOTE: "52002",
  NOT_REMOTE: "52003"
});

export const JOB_REMOTE_WORK_TYPE_T_LABELS = Object.freeze({
  [JOB_REMOTE_WORK_TYPE.FULLY_REMOTE]: "t_job_remote_work_fully_remote",
  [JOB_REMOTE_WORK_TYPE.PARTIALLY_REMOTE]: "t_job_remote_work_partially_remote",
  [JOB_REMOTE_WORK_TYPE.NOT_REMOTE]: "t_job_remote_work_not_remote"
});

/**
 * Constants related to the hiring process sections.
 * Note: Make sure to maintain the key order, it will display selection process based on the key order
 */

export const JOB_HIRING_FLOW_SECTIONS = Object.freeze({
  resume_screening: "resume_screening",
  interview: "interview",
  offer: "offer"
});

/**
 * Constants related to the hiring process for a job posting.
 */
export const JOB_HIRING_FLOW_RESUME_SCREENING = Object.freeze({
  RESUME_SCREENING: "300001",
  APTITUDE_TEST: "300002",
  PERSONALITY_TEST: "300003",
  SPI_TEST: "300004",
  SKILL_TEST: "300005",
  INTERNAL_CONFIRMATION: "300006"
});

export const JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS = Object.freeze({
  [JOB_HIRING_FLOW_RESUME_SCREENING.RESUME_SCREENING]:
    "t_general_resume_screening",
  [JOB_HIRING_FLOW_RESUME_SCREENING.APTITUDE_TEST]:
    "t_job_post_job_hiring_flow_resume_screening_option_aptitude_test",
  [JOB_HIRING_FLOW_RESUME_SCREENING.PERSONALITY_TEST]:
    "t_job_post_job_hiring_flow_resume_screening_option_personality_test",
  [JOB_HIRING_FLOW_RESUME_SCREENING.SPI_TEST]:
    "t_job_post_job_hiring_flow_resume_screening_option_spi_test",
  [JOB_HIRING_FLOW_RESUME_SCREENING.SKILL_TEST]:
    "t_job_post_job_hiring_flow_resume_screening_option_skill_test",
  [JOB_HIRING_FLOW_RESUME_SCREENING.INTERNAL_CONFIRMATION]:
    "t_job_post_job_hiring_flow_resume_screening_option_internal_confirmation_test"
});

export const JOB_HIRING_FLOW_INTERVIEW = Object.freeze({
  INTERVIEW: "400001",
  SECOND_ROUND: "400002",
  THIRD_ROUND: "400003",
  FOURTH_ROUND: "400004",
  FIFTH_ROUND: "400005"
});

export const JOB_HIRING_FLOW_INTERVIEW_T_LABELS = Object.freeze({
  [JOB_HIRING_FLOW_INTERVIEW.INTERVIEW]: "t_general_interview",
  [JOB_HIRING_FLOW_INTERVIEW.SECOND_ROUND]:
    "t_job_post_job_hiring_flow_interview_option_second_round",
  [JOB_HIRING_FLOW_INTERVIEW.THIRD_ROUND]:
    "t_job_post_job_hiring_flow_interview_option_third_round",
  [JOB_HIRING_FLOW_INTERVIEW.FOURTH_ROUND]:
    "t_job_post_job_hiring_flow_interview_option_fourth_round",
  [JOB_HIRING_FLOW_INTERVIEW.FIFTH_ROUND]:
    "t_job_post_job_hiring_flow_interview_option_fifth_round"
});

export const JOB_HIRING_FLOW_OFFER = Object.freeze({
  OFFER: "500001",
  BACKGROUND_CHECK: "500002",
  UNDER_NEGOTIATION: "500003",
  WAITING_FOR_SIGNATURE: "500004"
});

export const JOB_HIRING_FLOW_OFFER_T_LABELS = Object.freeze({
  [JOB_HIRING_FLOW_OFFER.OFFER]: "t_general_offer",
  [JOB_HIRING_FLOW_OFFER.BACKGROUND_CHECK]:
    "t_job_post_job_hiring_flow_offer_option_background_check",
  [JOB_HIRING_FLOW_OFFER.UNDER_NEGOTIATION]:
    "t_job_post_job_hiring_flow_offer_under_negotiation",
  [JOB_HIRING_FLOW_OFFER.WAITING_FOR_SIGNATURE]:
    "t_job_post_job_hiring_flow_offer_waiting_for_signature"
});

export const JOB_HIRING_FLOW_ALL_LABELS = Object.freeze({
  ...JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS,
  ...JOB_HIRING_FLOW_INTERVIEW_T_LABELS,
  ...JOB_HIRING_FLOW_OFFER_T_LABELS
});

/**
 * Constant for ja and en alpha3 code (mostly used for first language[japanese] and second language[english] in language sections)
 */
export const JA_ALPHA3_CODE = "JPN";
export const EN_ALPHA3_CODE = "ENG";

/**
 * Constant for japan country alpha3 code (used for providing default county value)
 */
export const JP_ALPHA3_CODE = "JPN";

/**
 * Constant for japan country alpha2 code (used for providing default country code in Telephone Input)
 */

export const JP_ALPHA2_CODE = "JP";

// Constant related to post a job steps
// job information page (step 2)
export const JOB_DESCRIPTION_FIELD_MAX_LENGTH = 5000;
export const MUST_REQUIREMENTS_FIELD_MAX_LENGTH = 5000;

// additional information page (step 6)
export const OTHER_DETAILS_FIELD_MAX_LENGTH = 5000;

// Max character count limit on free text fields
export const FREE_TEXT_FIELD_MAX_LENGTH = 200;

// Max character count limit on skill fields
export const SKILL_FIELD_MAX_LENGTH = 100;

// Max character count limit on message input text
export const MESSAGE_INPUT_TEXT_MAX_LENGTH = 4000;

// Max limit for showing "9+" in unread message badge
export const UNREAD_MESSAGE_COUNT_BADGE_MAX_LIMIT = 9;

// Max limit for showing "9+" in notification badge
export const UNREAD_NOTIFICATION_COUNT_BADGE_MAX_LIMIT = 9;

/**
 * Constant for firebase collections
 * Any collection starting with meta_ is for population of the list.
 * Any collection starting with admin_ is for admin purpose. Not to be accessed by Frontend.
 */

export const FIRESTORE_COLLECTIONS = Object.freeze({
  USERS: "users",
  SAVED_JOB_FILTERS: "saved_job_filters",
  SAVED_CANDIDATE_SEARCH_FILTERS: "saved_candidate_search_filters",
  COMPANIES: "companies",
  JOBS: "jobs",
  APPLICATIONS: "applications",
  META_COUNTRIES_CITIES: "meta_countries_cities",
  PURCHASED_RESUMES: "purchased_resumes",
  META_SKILLS: "meta_skills",
  ADMIN_INVITATIONS: "admin_invitations",
  SAVED_CANDIDATES: "saved_candidates",
  CONVERSATIONS: "conversations",
  MESSAGES: "messages",
  TEST_META_EMAILS: "test_meta_emails",
  TRANSACTIONS: "transactions",
  NOTIFICATIONS: "notifications",
  META_OPENAI_PROMPT_HISTORY: "meta_openai_prompt_history"
});

/**
 * Constants for Typesense.
 */
export const TYPESENSE_COLLECTIONS = Object.freeze({
  JOBS: "jobs",
  CANDIDATES: "candidates",
  SKILLS: "skills"
});

export const LOCATION_TYPE = Object.freeze({
  COUNTRY: "60001",
  CITY: "60002"
});

/**
 * Constant for Job Search Salary Filter.
 */

export const JOB_SEARCH_SALARY_FILTER = Object.freeze({
  THREE_MILLION: "3000000",
  FIVE_MILLION: "5000000",
  SEVEN_MILLION: "7000000",
  TEN_MILLION: "10000000",
  FIFTEEN_MILLION: "15000000",
  TWENTY_MILLION: "20000000"
});

export const JOB_SEARCH_SALARY_FILTER_T_LABELS = Object.freeze({
  [JOB_SEARCH_SALARY_FILTER.THREE_MILLION]:
    "t_job_search_salary_filter_3_million",
  [JOB_SEARCH_SALARY_FILTER.FIVE_MILLION]:
    "t_job_search_salary_filter_5_million",
  [JOB_SEARCH_SALARY_FILTER.SEVEN_MILLION]:
    "t_job_search_salary_filter_7_million",
  [JOB_SEARCH_SALARY_FILTER.TEN_MILLION]:
    "t_job_search_salary_filter_10_million",
  [JOB_SEARCH_SALARY_FILTER.FIFTEEN_MILLION]:
    "t_job_search_salary_filter_15_million",
  [JOB_SEARCH_SALARY_FILTER.TWENTY_MILLION]:
    "t_job_search_salary_filter_20_million"
});

/**
 * Constant for Range Slider Component.
 *
 * RANGE_SLIDER_STEP - https://mui.com/material-ui/api/slider/#prop-step.
 * RANGE_SLIDER_MIN - https://mui.com/material-ui/api/slider/#prop-min
 * RANGE_SLIDER_MAX - https://mui.com/material-ui/api/slider/#prop-max (Note:- second last value because last value is any)
 * RANGE_SLIDER_TOTAL_DISTANCE - Total of RANGE_SLIDER_MAX + RANGE_SLIDER_STEP is for showing the "Any" label with proper space, Note: Make sure to subtract the step value to get the actual value while submitting the form
 */
export const RANGE_SLIDER_STEP = 1;
export const RANGE_SLIDER_MIN = 0;
export const RANGE_SLIDER_MAX = 100;
export const RANGE_SLIDER_TOTAL_DISTANCE = RANGE_SLIDER_MAX + RANGE_SLIDER_STEP;

/**
 * Constant for Candidate Search Salary Slider.
 */
export const CANDIDATE_SEARCH_SALARY_SLIDER_STEP = 500000;
export const CANDIDATE_SEARCH_SALARY_SLIDER_MIN = 0;
export const CANDIDATE_SEARCH_SALARY_SLIDER_MAX = 20000000; // second last max value
export const CANDIDATE_SEARCH_SALARY_SLIDER_TOTAL_DISTANCE =
  CANDIDATE_SEARCH_SALARY_SLIDER_MAX + CANDIDATE_SEARCH_SALARY_SLIDER_STEP; // Here +CANDIDATE_SEARCH_SALARY_SLIDER_STEP is for showing the "Any" label with proper space, Note: Make sure to subtract the step value to get the actual value while submitting the form

/**
 * Constant for locales
 */

export const LOCALE = Object.freeze({
  EN: "en-US",
  JA: "ja-JP"
});

/**
 * Constant for locales-short
 * This is used to change location and country locale.
 */

export const LOCALE_SHORT = Object.freeze({
  EN: "en",
  JA: "ja"
});

/**
 * Constant for pagination
 */

export const PAGINATION = Object.freeze({
  ITEMS_PER_PAGE: 8,
  ITEMS_PER_PAGE_LARGE: 20,
  TABLE_ROWS_PER_PAGE: [10, 50, 100]
});

/**
 * Constant for job dates related tags
 */

export const JOB_NEW_TAG_LAST_UPDATED_DAY = 32;
export const JOB_ACTIVE_TAG_COMPANY_LAST_LOGIN_DAY = 8;

/**
 * Constant for candidate dates related tags
 */

export const CANDIDATE_ACTIVE_TAG_LAST_LOGIN_DAY = 8;

/**
 * Constant for job posting steps
 */
export const TOTAL_JOB_POSTING_STEPS = 8;

/**
 * Constant related to number of digits to show in the salary of Job Search.
 */
export const JOB_SEARCH_SALARY_DISPLAY_DIGIT_COUNT = 5;

/**
 * Constants related to sort dropdown (JOB_SEARCH_SORT_ORDER)
 * This is used to define the sort order in the Job Search.
 *
 * ACTIVE  - Default Search, Active First.
 * NEWEST   - Newest Job Post first.
 */
export const JOB_SEARCH_SORT_ORDER = Object.freeze({
  ACTIVE: "active",
  NEWEST: "newest"
});

export const JOB_SEARCH_SORT_ORDER_T_LABELS = Object.freeze({
  [JOB_SEARCH_SORT_ORDER.ACTIVE]: "t_job_search_sort_active",
  [JOB_SEARCH_SORT_ORDER.NEWEST]: "t_job_search_sort_newest"
});

/**
 * Constants related to resume purchased action. (RESUME_PURCHASE_STATUS)
 * These constants tell whether the resume purchase action is fine or not.
 * Or if the company has asked for refund of this resume purchase.
 */
export const RESUME_PURCHASE_STATUS = Object.freeze({
  OK: "61001",
  REFUNDED: "61002"
});

/**
 * Constant related to the Status of the Skill in Skill Profile
 */
export const SKILL_STATUS = Object.freeze({
  PENDING: "62001",
  OK: "62002"
});

/**
 * Constant related to the user can apply for a job or not
 */
export const JOB_APPLY_STATUS_CODE = Object.freeze({
  UNAUTHENTICATED: "unauthenticated",
  EMAIL_NOT_VERIFIED: "email-not-verified",
  PROFILE_NOT_COMPLETED: "profile-not-completed",
  CAN_APPLY: "can-apply",
  CAN_NOT_APPLY: "can-not-apply",
  HIDDEN: "hidden"
});

/**
 * Constant related to the user can apply for a job or not
 */
export const JOB_POST_STATUS_CODE = Object.freeze({
  EMAIL_NOT_VERIFIED: "email-not-verified",
  CAN_POST: "can-post",
  CAN_NOT_POST: "can-not-post"
});

/**
 * Constant related to other services from where we import jobs.
 * This is used for internal references (Mostly assigned to the job metadata)
 */
export const EXTERNAL_JOB_REFERENCE_PLATFORMS = Object.freeze({
  CAREERBANK_JOBSEARCH: "careerbank_jobsearch"
});

/**
 * Constant related to language switcher.
 * This is used for hiding and showing language switcher on different pages
 */
export const HIDE_LANGUAGE_SWITCHER_PATHS = [
  "/employers/jobs/*",
  "/profile/edit/*"
];

/**
 * Constant related to CV file requirements checkbox values.
 * These values are used to determine if the checkboxes for English CV, Japanese CV, and Japanese Rirekisho are checked.
 */

export const CV_FILE_REQUIREMENTS_CHECKBOX_VALUES = Object.freeze({
  EN_CV_CHECKED: "enCvChecked",
  JA_CV_CHECKED: "jaCvChecked",
  JA_RIREKISHO_CHECKED: "jaRirekishoChecked"
});

/**
 * Constant related to Certification No Expire checkbox value.
 * This values is used to determine if the checkbox for no expiry date on certain certification are checked.
 */

export const CERTIFICATION_EXPIRY_DATE_CHECKBOX_VALUES = Object.freeze({
  NO_EXPIRY_CHECKED: 1
});

/**
 * Constant related to JobDetails current job checkbox value.
 * This values is used to determine if the checkbox for current job on certain job details are checked.
 */

export const JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES = Object.freeze({
  IS_CURRENT_JOB_CHECKED: 1
});

/**
 * Constant related to Profile Summary No Experience checkbox value.
 * This values is used to determine if the checkbox for no experience on job overview section of  profile summary are checked.
 */

export const PROFILE_SUMMARY_NO_EXPERIENCE_CHECKBOX_VALUES = Object.freeze({
  NO_EXPERIENCE_CHECKED: 1
});

/**
 * Constant related to Google Analytics Event
 */

export const ANALYTICS_EVENT_TYPE = Object.freeze({
  SEARCH_JOB: "search_job",
  SEARCH_CANDIDATE: "search_candidate",
  EMAIL_SIGN_UP: "sign_up",
  GOOGLE_SIGNIN_SIGNUP: "google_sign_in_sign_up",
  LINKEDIN_SIGNIN_SIGNUP: "linkedin_sign_in_sign_up",
  EMPLOYER_HP_LOAD: "employers_home_page_load",
  CANDIDATE_HP_LOAD: "candidate_home_page_load",
  VIEW_JOB_PAGE: "view_job_page"
});

/**
 * Constant related to message type.
 * These values are used to determine if the message is send by user or employer or notification
 */

export const MESSAGE_TYPE = Object.freeze({
  FROM_COMPANY: "11101",
  FROM_CANDIDATE: "11102",
  NOTIFICATION: "11103"
});

/**
 * Constant related to PDF Zoom Level.
 *
 */

export const ZOOM_VALUES = Object.freeze({
  MAX: 2,
  MIN: 0.5,
  STEP: 0.1
});

/**
 * Constant related declining job invitation(candidates side).
 *
 */

export const CANDIDATE_JOB_DECLINED_REASONS = Object.freeze({
  CANDIDATE_JOB_DECLINED_REASON_SALARY_NOT_MATCH: "70001",
  CANDIDATE_JOB_DECLINED_REASON_LOCATION_NOT_MATCH: "70002",
  CANDIDATE_JOB_DECLINED_REASON_TAKE_DIFFERENT_OFFER: "70003",
  CANDIDATE_JOB_DECLINED_REASON_SKILL_NOT_MATCH: "70004",
  CANDIDATE_JOB_DECLINED_REASON_COMPANY_CULTURE_NOT_MATCH: "70005"
});

export const CANDIDATE_JOB_DECLINED_REASONS_T_LABELS = Object.freeze({
  [CANDIDATE_JOB_DECLINED_REASONS.CANDIDATE_JOB_DECLINED_REASON_SALARY_NOT_MATCH]:
    "t_candidate_job_declined_reason_salary_not_match",
  [CANDIDATE_JOB_DECLINED_REASONS.CANDIDATE_JOB_DECLINED_REASON_LOCATION_NOT_MATCH]:
    "t_candidate_job_declined_reason_location_not_match",
  [CANDIDATE_JOB_DECLINED_REASONS.CANDIDATE_JOB_DECLINED_REASON_TAKE_DIFFERENT_OFFER]:
    "t_candidate_job_declined_reason_take_different_offer",
  [CANDIDATE_JOB_DECLINED_REASONS.CANDIDATE_JOB_DECLINED_REASON_SKILL_NOT_MATCH]:
    "t_candidate_job_declined_reason_skill_not_match",
  [CANDIDATE_JOB_DECLINED_REASONS.CANDIDATE_JOB_DECLINED_REASON_COMPANY_CULTURE_NOT_MATCH]:
    "t_candidate_job_declined_reason_company_culture_not_match"
});

/**
 * Constant related revoking job (candidates side).
 *
 */

export const CANDIDATE_JOB_REVOKED_REASONS = Object.freeze({
  CANDIDATE_JOB_REVOKED_REASON_SALARY_NOT_MATCH: "80001",
  CANDIDATE_JOB_REVOKED_REASON_LOCATION_NOT_MATCH: "80002",
  CANDIDATE_JOB_REVOKED_REASON_TAKE_DIFFERENT_OFFER: "80003",
  CANDIDATE_JOB_REVOKED_REASON_SKILL_NOT_MATCH: "80004",
  CANDIDATE_JOB_REVOKED_REASON_COMPANY_CULTURE_NOT_MATCH: "80005"
});

export const CANDIDATE_JOB_REVOKED_REASONS_T_LABELS = Object.freeze({
  [CANDIDATE_JOB_REVOKED_REASONS.CANDIDATE_JOB_REVOKED_REASON_SALARY_NOT_MATCH]:
    "t_candidate_job_revoked_reason_salary_not_match",
  [CANDIDATE_JOB_REVOKED_REASONS.CANDIDATE_JOB_REVOKED_REASON_LOCATION_NOT_MATCH]:
    "t_candidate_job_revoked_reason_location_not_match",
  [CANDIDATE_JOB_REVOKED_REASONS.CANDIDATE_JOB_REVOKED_REASON_TAKE_DIFFERENT_OFFER]:
    "t_candidate_job_revoked_reason_take_different_offer",
  [CANDIDATE_JOB_REVOKED_REASONS.CANDIDATE_JOB_REVOKED_REASON_SKILL_NOT_MATCH]:
    "t_candidate_job_revoked_reason_skill_not_match",
  [CANDIDATE_JOB_REVOKED_REASONS.CANDIDATE_JOB_REVOKED_REASON_COMPANY_CULTURE_NOT_MATCH]:
    "t_candidate_job_revoked_reason_company_culture_not_match"
});

/**
 * Constant related declining candidate(employer side).
 *
 */

export const EMPLOYER_DECLINED_CANDIDATE_REASONS = Object.freeze({
  EMPLOYER_DECLINED_CANDIDATE_REASON_SKILL_NOT_MATCH: "90001",
  EMPLOYER_DECLINED_CANDIDATE_REASON_LANGUAGE_NOT_MATCH: "90002",
  EMPLOYER_DECLINED_CANDIDATE_REASON_EXPECTED_SALARY_NOT_MATCH: "90003",
  EMPLOYER_DECLINED_CANDIDATE_REASON_LOCATION_NOT_MATCH: "90004",
  EMPLOYER_DECLINED_CANDIDATE_REASON_YEAR_OF_EXPERIENCE_NOT_MATCH: "90005",
  EMPLOYER_DECLINED_CANDIDATE_REASON_JOB_CLOSED: "90006"
});

export const EMPLOYER_DECLINED_CANDIDATE_REASONS_T_LABELS = Object.freeze({
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_SKILL_NOT_MATCH]:
    "t_employer_declined_candidate_reason_skill_not_match",
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_LANGUAGE_NOT_MATCH]:
    "t_employer_declined_candidate_reason_language_not_match",
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_EXPECTED_SALARY_NOT_MATCH]:
    "t_employer_declined_candidate_reason_salary_not_match",
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_LOCATION_NOT_MATCH]:
    "t_employer_declined_candidate_reason_location_not_match",
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_YEAR_OF_EXPERIENCE_NOT_MATCH]:
    "t_employer_declined_candidate_reason_year_of_experience_not_match",
  [EMPLOYER_DECLINED_CANDIDATE_REASONS.EMPLOYER_DECLINED_CANDIDATE_REASON_JOB_CLOSED]:
    "t_employer_declined_candidate_reason_job_closed"
});

/**
 * Constant related to animation timeout.
 *
 */

export const ANIMATION_TIMEOUT_MS = Object.freeze({
  FAST: 60,
  SLOW: 125
});

/**
 * Constants related to Stripe operational modes.
 * This constant object defines the modes under which Stripe transactions can be processed.
 *
 * PAYMENT: Used for one-time payments. This mode is suitable for single transactions where a customer pays once for a product or service.
 * SUBSCRIPTION: Used for recurring payments. This mode supports scenarios where customers pay on a periodic basis for continued access to a product or service.
 */

export const STRIPE_MODE = Object.freeze({
  PAYMENT: "payment",
  SUBSCRIPTION: "subscription"
});

/**
 * Constants related to Subscription Plan Types.
 *
 * FREE: Indicates the free basic subscription plan.
 * STARTER: Indicates the standard subscription plan with additional features.
 * PRO: Indicates the advanced subscription plan with all features included.
 */

export const STRIPE_SUBSCRIPTION_PLAN = Object.freeze({
  FREE: "FREE",
  STARTER: "STARTER",
  PRO: "PRO"
});

/**
 * Constants related to payment frequency for plan subscriptions.
 *
 * MONTHLY: Indicates payments are made on a monthly basis.
 * YEARLY: Indicates payments are made annually.
 */

export const SUBSCRIPTION_PLAN_TERM = Object.freeze({
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY"
});

/**
 * Constants for Credit Transaction Types.
 * Defines types of transactions related to account credits. Each type represents a distinct action:
 *
 * - REFUND: Credits returned to the account, often due to cancellations.
 * - PURCHASE: Credits added to the account, typically from buying or promotions.
 * - USE: Credits deducted for services like purchasing resume.
 * - EXPIRY: Credits removed when they expire and become unusable.
 * - CANCEL: All credits removed when user has requested immediate subscription cancellation.
 */

export const CREDIT_TRANSACTION_TYPE = Object.freeze({
  REFUND: "REFUND",
  PURCHASE: "PURCHASE",
  USE: "USE",
  EXPIRY: "EXPIRY",
  CANCEL: "CANCEL"
});

/**
 * Constants for credit change types in account operations.
 *
 * CREDIT: Used to increment the account balance. Commonly applied in scenarios like credit
 *      purchases or rewards where additional credits are added to the account.
 *
 * DEBIT: Used to decrement the account balance. Relevant in situations where credits are
 *      expended or adjustments are made for utilizing services.
 */

export const CREDIT_CHANGE_TYPE = Object.freeze({
  CREDIT: "CREDIT",
  DEBIT: "DEBIT"
});

/**
 * Defines the quantity of monthly credits allocated to each subscription type.
 *
 * - STARTER: Represents the number of credits a user receives each month under the standard subscription plan.
 * - PRO: Represents the number of credits a user receives each month under the advanced subscription plan.
 */

export const SUBSCRIPTION_MONTHLY_CREDITS_QTY = Object.freeze({
  STARTER: 5,
  PRO: 10
});

/**
 * Defines the upper and lower limit for credit purchase quantity
 */

export const CREDIT_PURCHASE_QTY_LIMIT = Object.freeze({
  MIN: 1,
  MAX: 1000
});

/**
 * Defines for how long (in years & months) credits can remain valid before expiring.
 */

export const CREDIT_EXPIRY_YEAR = 10;
export const CREDIT_EXPIRY_MONTHS = CREDIT_EXPIRY_YEAR * 12;

/**
 * Constant related to the manager/company can purchase a resume or not
 */

export const RESUME_PURCHASE_STATUS_CODE = Object.freeze({
  UNAUTHENTICATED: "unauthenticated",
  CAN_PURCHASE: "can-purchase",
  CANDIDATE_REVOKED: "candidate-revoked",
  CANDIDATE_NOT_APPLIED: "candidate-not-applied",
  ALREADY_PURCHASED: "already-purchased",
  NO_CREDITS: "no-credits-available"
});

/**
 * Defines various actions that can occur during the lifecycle of a subscription. These actions
 * are used to manage and track changes to user subscriptions within the system, facilitating
 * effective handling of transitions such as cancellations, pauses, renewals, and modifications.
 *
 *  CANCELLED: Indicates that the subscription has been cancelled, either by the user or the provider.
 *  RESUMED: Indicates that a previously paused subscription has been resumed.
 *  RENEWED: Indicates that the subscription has been automatically renewed for another billing period.
 *  CHANGED: Indicates that the subscription details have been modified, such as plan or billing cycle changes.
 *  PAUSED: Indicates that the subscription has been paused.
 */

export const SUBSCRIPTION_UPDATE_ACTION = Object.freeze({
  CANCELLED: "CANCELLED",
  RESUMED: "RESUMED",
  RENEWED: "RENEWED",
  CHANGED: "CHANGED",
  PAUSED: "PAUSED"
});

/**
 * Defines the possible states for a subscription plan.
 *
 *  ACTIVE: The subscription is active and the user has access to the plan's features.
 *  PAUSED: The subscription is temporarily suspended. The user might not have access to all features, and billing is paused.
 *  PAYMENT_FAILED: A payment for the subscription has failed, and the plan might be at risk of being canceled or downgraded if not resolved.
 */

export const SUBSCRIPTION_PLAN_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  PAYMENT_FAILED: "PAYMENT_FAILED"
});

/**
 * Constant used to mark Tokhimo Admin as the user initiating an action.
 */

export const TOKHIMO_ADMIN = "TOKHIMO_ADMIN";

/**
 * Maximum size of the related skill list.
 * This is applied on both Candidate side and Employer Job Posting side.
 */
export const RELATED_SKILL_LIST_MAX_SIZE = 10;

/**
 * Defines a set of constant tour names used in the FTUI (First Time User Interface) system.
 * These tour names are used to track user progress and customize the FTUI experience.
 */

export const FTUI_TOUR_NAME = Object.freeze({
  CANDIDATE_HOME_PAGE: "home_page",
  CANDIDATE_PROFILE_PAGE: "profile_page",
  CANDIDATE_RESUME_PAGE: "resume_page"
});

/**
 * Defines the FTUI Tour current status.
 *
 * NOT_STARTED: The tour has not yet been initiated.
 * IN_PROGRESS: The tour is currently being performed.
 * COMPLETED: The tour has been successfully finished.
 */

export const FTUI_TOUR_STATUS = Object.freeze({
  NOT_STARTED: "not-started",
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed"
});

/**
 * Constant related deactivating account (company).
 *
 */
export const COMPANY_ACCOUNT_DEACTIVATION_REASONS = Object.freeze({
  FILLED_ALL_OPEN_POSITIONS: "810001",
  EXCESSIVE_UNQUALIFIED_APPLICATIONS: "810002",
  HIGH_COST: "810003",
  BETTER_RECRUITMENT_CHANNELS: "810004",
  PRIVACY_CONCERNS: "810005",
  POOR_CUSTOMER_SERVICE: "810006",
  OUTDATED_FEATURES: "810007",
  INADEQUATE_CANDIDATE_POOL: "810008",
  TECHNICAL_ISSUES: "810009",
  COMPANY_POLICY_CHANGE: "810010"
});

export const COMPANY_ACCOUNT_DEACTIVATION_REASONS_T_LABELS = Object.freeze({
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.FILLED_ALL_OPEN_POSITIONS]:
    "t_deactivation_reason_company_filled_all_open_positions",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.EXCESSIVE_UNQUALIFIED_APPLICATIONS]:
    "t_deactivation_reason_company_excessive_unqualified_applications",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.HIGH_COST]:
    "t_deactivation_reason_company_high_cost",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.BETTER_RECRUITMENT_CHANNELS]:
    "t_deactivation_reason_company_better_recruitment_channels",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.PRIVACY_CONCERNS]:
    "t_deactivation_reason_company_privacy_concerns",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.POOR_CUSTOMER_SERVICE]:
    "t_deactivation_reason_company_poor_customer_service",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.OUTDATED_FEATURES]:
    "t_deactivation_reason_company_outdated_features",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.INADEQUATE_CANDIDATE_POOL]:
    "t_deactivation_reason_company_inadequate_candidate_pool",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.TECHNICAL_ISSUES]:
    "t_deactivation_reason_company_technical_issues",
  [COMPANY_ACCOUNT_DEACTIVATION_REASONS.COMPANY_POLICY_CHANGE]:
    "t_deactivation_reason_company_company_policy_change"
});

/**
 * Constant related to notification type.
 */

export const NOTIFICATION_TYPE = Object.freeze({
  JOB_UPDATE: "1000001",
  PROMO: "1000002",
  ALERT: "1000003"
});

export const NOTIFICATION_TYPE_T_LABELS = Object.freeze({
  [NOTIFICATION_TYPE.JOB_UPDATE]: "t_notification_type_job_update",
  [NOTIFICATION_TYPE.PROMO]: "t_notification_type_promo",
  [NOTIFICATION_TYPE.ALERT]: "t_notification_type_alert"
});

/**
 * Constant related to notification status.
 */

export const NOTIFICATION_STATUS = Object.freeze({
  READ: "READ",
  UNREAD: "UNREAD",
  DELETED: "DELETED"
});

/**
 * Constant related deactivating account (candidate).
 *
 */
export const CANDIDATE_ACCOUNT_DEACTIVATION_REASONS = Object.freeze({
  CANDIDATE_SECURED_EMPLOYMENT: "900001",
  CANDIDATE_PRIVACY_CONCERNS: "900002",
  CANDIDATE_EXCESSIVE_COMMUNICATION: "900003",
  CANDIDATE_DISSATISFIED_WITH_SERVICE: "900004",
  CANDIDATE_LACK_OF_JOB_LISTINGS: "900005",
  CANDIDATE_FOUND_BETTER_PLATFORM: "900006",
  CANDIDATE_INADEQUATE_JOB_MATCHES: "900007"
});

export const CANDIDATE_ACCOUNT_DEACTIVATION_REASONS_T_LABELS = Object.freeze({
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_SECURED_EMPLOYMENT]:
    "t_deactivation_reason_candidate_secured_employment",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_PRIVACY_CONCERNS]:
    "t_deactivation_reason_candidate_privacy_concerns",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_EXCESSIVE_COMMUNICATION]:
    "t_deactivation_reason_candidate_excessive_communication",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_DISSATISFIED_WITH_SERVICE]:
    "t_deactivation_reason_candidate_dissatisfied_with_service",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_LACK_OF_JOB_LISTINGS]:
    "t_deactivation_reason_candidate_lack_of_job_listings",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_FOUND_BETTER_PLATFORM]:
    "t_deactivation_reason_candidate_found_better_platform",
  [CANDIDATE_ACCOUNT_DEACTIVATION_REASONS.CANDIDATE_INADEQUATE_JOB_MATCHES]:
    "t_deactivation_reason_candidate_inadequate_job_matches"
});

/**
 * Constant related to job information section type, It is used for generating auto job description and must requirement in the job posting(job information page).
 *
 */
export const JOB_INFORMATION_SECTION_TYPE = Object.freeze({
  JOB_DESCRIPTION: "JOB_DESCRIPTION",
  MUST_REQUIREMENT: "MUST_REQUIREMENT"
});

/**
 * Constant related user permissions (company and company manager).
 *
 */
export const USER_PERMISSIONS = Object.freeze({
  CAN_EDIT_COMPANY_PROFILE: "canEditCompanyProfile",
  CAN_UPSERT_JOB: "canUpsertJob",
  CAN_VIEW_JOB: "canViewJob",
  CAN_INVITE_CANDIDATE: "canInviteCandidate",
  CAN_VIEW_APPLICATION: "canViewApplication",
  CAN_PURCHASE_RESUME: "canPurchaseResume",
  CAN_INVITE_MANAGER: "canInviteManager",
  CAN_EDIT_IAM: "canEditIAM"
});

export const USER_PERMISSIONS_T_LABELS = Object.freeze({
  [USER_PERMISSIONS.CAN_EDIT_COMPANY_PROFILE]:
    "t_user_permissions_can_edit_company_profile",
  [USER_PERMISSIONS.CAN_UPSERT_JOB]: "t_user_permissions_can_upsert_job",
  [USER_PERMISSIONS.CAN_VIEW_JOB]: "t_user_permissions_can_view_job",
  [USER_PERMISSIONS.CAN_INVITE_CANDIDATE]:
    "t_user_permissions_can_invite_candidate",
  [USER_PERMISSIONS.CAN_VIEW_APPLICATION]:
    "t_user_permissions_can_view_application",
  [USER_PERMISSIONS.CAN_PURCHASE_RESUME]:
    "t_user_permissions_can_purchase_resume",
  [USER_PERMISSIONS.CAN_INVITE_MANAGER]:
    "t_user_permissions_can_invite_manager",
  [USER_PERMISSIONS.CAN_EDIT_IAM]: "t_user_permissions_can_edit_iam"
});
